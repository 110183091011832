vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Revitalización con vitaminas de ácido hialurónico</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt=" revitalización con vitaminas de ácido hialurónico" src="../../assets/medicinaEsteticaRevitalizacionConVitaminasDeAcidoHialuronico.jpg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>
                            La revitalización con vitaminas de <span class="p-text-bold">ácido hialurónico</span> se emplea
                            para conseguir un rejuvenecimiento de la piel, recuperando así la <span
                                class="p-text-bold">elasticidad</span>, <span class="p-text-bold">hidratación</span> y <span
                                class="p-text-bold">luminosidad</span> perdida por el paso de los años y el efecto del sol.
                            Este tratamiento nos ayudará a estimular la formación de <span
                                class="p-text-bold">colágeno</span> y la <span class="p-text-bold">elastina</span>.
                        </p>
                        <p>
                            Se comienza el tratamiento realizando una <span class="p-text-bold">limpieza</span> y <span
                                class="p-text-bold">exfoliación</span> en profundidad. Seguidamente se aplica una crema
                            <span class="p-text-bold">anestésica</span> para evitar posibles molestias. Posteriormente se
                            realiza una <span class="p-text-bold">infiltración</span> intradérmica del ácido hialurónico no
                            reticulado en toda la zona a tratar y se sella el tratamiento con un <span
                                class="p-text-bold">serum revitalizante</span> de la piel.
                        </p>
                        <p>
                            Se recomiendan varias sesiones dependiendo de lo que la persona necesite mejorar. Después de
                            cada sesión se produce un leve efecto <span class="p-text-bold">lifting</span> y la piel queda
                            muy hidratada y luminosa; la recuperación es inmediata, por lo que tras el tratamiento puedes
                            continuar con tu rutina diaria.
                        </p>

                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'RevitalizacionVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>